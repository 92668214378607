<template>
  <div class="paginacao">
    <!-- Botão para página anterior -->
    <div class="navegacao-paginas">
      <button
        v-if="showPrevious"
        class="btn-paginacao"
        @click="changePage(current - 1)"
      >
        <i class="fas fa-chevron-left"></i>
      </button>

      <!-- Páginas sequenciais -->
      <button
        v-for="page in visiblePages"
        :key="page"
        class="item"
        :class="{ 'btn-paginacao': true, 'pagina-atual': page === current }"
        @click="changePage(page)"
      >
        {{ page }}
      </button>

      <!-- Elipses e botão da última página -->
      <span v-if="showEllipsis" class="p-2">...</span>
      <button
        v-if="showLast"
        class="btn-paginacao"
        @click="changePage(pages.length)"
      >
        {{ pages.length }}
      </button>

      <!-- Botão para próxima página -->
      <button 
        v-if="showNext" 
        class="btn-paginacao" 
        @click="changePage(current + 1)"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>

    <!-- Exibir campo para entrada manual apenas se o número de páginas for maior que 10 -->
    <div class="input-pagina mt-4" v-if="pages.length > 10">
      <input 
        type="number" 
        v-model.number="inputPage" 
        @keypress.enter="goToPage" 
        placeholder="Digite a página"
        :min="1" 
        :max="pages.length" 
        class="input-paginacao"
      />
      <button class="btn-ir" @click="goToPage">Ir</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    offset: {
      type: [String, Number],
      default: 0,
    },
    total: {
      type: [String, Number],
      required: true,
    },
    limit: {
      type: [String, Number],
      default: 20, // Atualizei o valor default de acordo com sua exibição de 20 por página
    },
    maxVisiblePages: {
      type: [String, Number],
      default: 5, // Exibir 5 páginas de cada vez
    },
  },
  data() {
    return {
      inputPage: this.current // Iniciando o campo de input da página com a página atual
    };
  },
  computed: {
    showPrevious() {
      return this.current > 1;
    },
    showNext() {
      return this.current < this.pages.length;
    },
    showLast() {
      return this.pages.length > this.maxVisiblePages && this.current <= this.pages.length - this.maxVisiblePages;
    },
    showEllipsis() {
      return this.pages.length > this.maxVisiblePages && this.current < this.pages.length - this.maxVisiblePages + 1;
    },
    current() {
      return parseInt(this.offset) || 1;
    },
    pages() {
      const qty = Math.ceil(this.total / this.limit);
      return Array.from({ length: qty }, (v, i) => i + 1);
    },
    visiblePages() {
      const startPage = Math.max(this.current - Math.floor(this.maxVisiblePages / 2), 1);
      const endPage = Math.min(startPage + this.maxVisiblePages - 1, this.pages.length);
      return this.pages.slice(startPage - 1, endPage);
    },
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.pages.length) {
        this.$emit('change-page', page);
      }
    },
    goToPage() {
      if (this.inputPage >= 1 && this.inputPage <= this.pages.length) {
        this.changePage(this.inputPage);
        this.inputPage = ''
      } else {
        alert(`Digite um número de página válido entre 1 e ${this.pages.length}`);
      }
    }
  }
};
</script>

<style scoped>
.paginacao {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.navegacao-paginas {
  display: flex;
  align-items: center;
  gap: 6px;
}

.input-pagina {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.input-paginacao {
  width: 150px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-ir {
  margin-left: 5px;
  padding: 5px 10px;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.pagina-atual {
  color: white;
  @apply bg-gray-800;
}
</style>
