<template>
    <div class="container-formulario">
        <!-- Cabeçalho -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Vãos da Obra</h1>
            </div>

            <router-link to="/obras" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <div class="container-cabecalho justify-end flex mb-4">
            <button @click="$router.push(`/criar-vao/${this.$route.params.id}`)"
                class="btn-add-fisica whitespace-nowrap">
                Novo Vão
            </button>
        </div>

        <!-- Listagem dos itens da obra -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Foto</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Descrição</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Local</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Altura (MM)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Largura (MM)</th>
                        <th class="py-2 px-4 bg-gray-200 border-b"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="vao in listaVaos" :key="vao.id">
                        <tr class="escopo-tabela">
                            <td class="py-2 px-4 flex gap-2">
                                <button @click="deleteVao(vao.id)" class="text-gray-500" title="Excluir Vão">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button @click="$router.push(`/editar-vao/${$route.params.id}/${vao.id}`)"
                                    class="text-gray-500" title="Editar Vão">
                                    <i class="fas fa-paperclip"></i>
                                </button>
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Verifica se o vão tem uma imagem -->
                                <img v-if="vao.imagem && vao.imagem.url_documento" :src="vao.imagem.url_documento"
                                    alt="Foto do Vão" class="foto-vao" @click="abrirModal(vao.imagem.url_documento)" />

                                <!-- Se não houver imagem, exibe uma mensagem alternativa -->
                                <img v-else class="foto-vao" src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png" alt="Sem foto">
                            </td>
                            <td class="py-2 px-4 border-b">{{ vao.descricao }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.local_vao }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.altura }}</td>
                            <td class="py-2 px-4 border-b">{{ vao.largura }}</td>
                            <td class="py-2 px-4 border-b">
                                <button @click="selecionarVao(vao)" class="text-gray-500" title="Abrir Projetos do Vão">
                                    <i
                                        :class="vaosSelecionados.some(v => v.id === vao.id) ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                </button>
                            </td>
                        </tr>

                        <!-- Detalhes dos Projetos e Linhas relacionadas -->
                        <tr v-if="vaosSelecionados.some(v => v.id === vao.id)" class="bg-gray-200">
                            <td colspan="8" class="px-4 py-2">
                                <div>
                                    <ul v-if="vao.projeto && vao.projeto.length > 0"
                                        class="flex flex-wrap justify-center gap-6">
                                        <li v-for="(projeto, index) in vao.projeto" :key="index"
                                            class="w-full sm:w-1/2 lg:w-1/4 p-4 bg-white border border-corBase/40 shadow-md rounded-md flex flex-col items-center">
                                            <input type="radio" v-model="vao.selectedProjetoIndex" :value="index"
                                                class="mb-4">
                                            <img src="@/assets/imagens/fotos/janela2folhas.png" alt="Imagem do Projeto"
                                                class="w-32 h-32 object-cover rounded mb-4">

                                            <div v-for="(nameProjeto, i) in projeto.projeto" :key="i"
                                                class="text-center">
                                                <strong class="block text-base font-semibold">Projeto:</strong>
                                                <span class="text-gray-700 whitespace-nowrap text-sm pb-3">{{
                                                    nameProjeto.descricao_fixa }}</span>
                                            </div>

                                            <strong class="block text-base font-semibold">Linha:</strong>
                                            <span class="text-gray-700 whitespace-nowrap text-sm pb-3">{{
                                                projeto.linhas[0].nome }}</span>

                                            <button @click.prevent="mostrarVariaveis(projeto.item_orcamento)"
                                                class="btn-salvar">Variáveis do Projeto</button>
                                        </li>
                                    </ul>
                                    <p v-else>Sem projetos cadastrados para este vão.</p>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <Pagination v-if="listaVaos.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
                @change-page="mudarPagina" />
        </div>

        <div class="flex justify-end mt-4">
            <button class="btn-salvar" @click.prevent="gerarOrcamento()">Gerar Orçamento</button>
        </div>

        <!-- Modal para exibir a foto ampliada -->
        <div v-if="fotoAmpliada" class="modal-mask" @click="fecharModal">
            <div class="modal-wrapper">
                <img :src="fotoAmpliada" class="modal-foto" />
            </div>
        </div>
    </div>

    <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
    <ModalVariaveisOrcamento v-if="mostrarModalVariaveis" :id="idOrcamento"
        @fecharModalVariaveis="fecharModalVariaveis" />
</template>


<script>
import axios from 'axios';
import Pagination from '@/utils/Pagination.vue';
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import ModalVariaveisOrcamento from '@/components/cadastros_orcamentos_componentes/ModalVariaveisOrcamento.vue';

export default {
    name: "ListarVaos",
    data() {
        return {
            listaVaos: [],
            vaosSelecionados: [],

            projetos: [],
            linhas: [],

            fotoAmpliada: null,

            paginaAtual: 1,
            countItens: '',

            sucesso: false,
            sucessoMessage: '',
            erro: false,
            erroMessage: '',

            selectedLinha: '',

            idOrcamento: '',
            mostrarModalVariaveis: false,
        };
    },
    components: {
        Pagination,
        SucessoRapido,
        ErroRapido,
        ModalVariaveisOrcamento
    },
    created() {
        this.getVaos(1);
    },

    methods: {

        fecharModalVariaveis() {
            this.mostrarModalVariaveis = false;
        },

        // Gerar Orçamento com os Projetos e Linhas selecionadas
        async gerarOrcamento() {
            const orcamentoDetalhes = this.vaosSelecionados.map(vao => {
                const projetoSelecionado = vao.projeto[vao.selectedProjetoIndex];

                // Verifica se existe um projeto selecionado
                if (projetoSelecionado) {
                    return {
                        descricaoVao: vao.descricao,
                        projetoNome: projetoSelecionado.projeto[0].descricao_fixa,
                        projetoId: projetoSelecionado.projeto[0].id,
                        linha: projetoSelecionado.linhas[0].nome,
                        linhaId: projetoSelecionado.linhas[0].id,
                        item_orcamento: projetoSelecionado.item_orcamento
                    };
                }
            }).filter(item => item !== undefined);

            console.log("Detalhes do Orçamento:", orcamentoDetalhes);
            this.sucesso = true
            this.sucessoMessage = 'Orçamento gerado com sucesso.'
            setTimeout(() => {
                this.sucesso = false;
                this.sucessoMessage = ''
            }, 3000);
        },

        // Abrir modal quando clicar em um vão
        async selecionarVao(vao) {
            const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao.id);

            if (vaoIndex === -1) {
                if (!vao.hasOwnProperty('selectedProjetoIndex')) {
                    vao.selectedProjetoIndex = null;
                }

                this.vaosSelecionados.push(vao);
                await this.getLinhasProjetoVao(vao.id);

            } else {
                this.vaosSelecionados.splice(vaoIndex, 1);
            }
        },

        // Buscar lista de vãos da obra
        async getVaos(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/vao-obra/?obra=${this.$route.params.id}&page=${pagina}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.listaVaos = response.data.results;
                // console.log(this.listaVaos)
                this.countItens = response.data.count;
            } catch (error) {
                console.error(error);
            }
        },

        // Buscar Projetos e Linhas do Vão selecionado
        async getLinhasProjetoVao(vao_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/linhas-projeto-vao-obra/?vao=${vao_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                console.log(response.data.results)

                const vaoIndex = this.vaosSelecionados.findIndex(v => v.id === vao_id);
                if (vaoIndex !== -1) {
                    this.vaosSelecionados[vaoIndex].projeto = response.data.results;
                }
            } catch (error) {
                console.error('Erro ao buscar projetos:', error);
            }
        },

        // Deletar vão da lista
        async deleteVao(vao_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                await axios.patch(`obra/vao-obra/${vao_id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });

                this.getVaos(1);
            } catch (error) {
                console.error(error);
            }
        },

        async mostrarVariaveis(orcamentoId) {
            this.idOrcamento = orcamentoId
            this.mostrarModalVariaveis = true
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina;
            this.getVaos(pagina);
        },

        abrirModal(foto) {
            this.fotoAmpliada = foto;
        },
        fecharModal() {
            this.fotoAmpliada = null;
        },
    }
}
</script>


<style scoped>
.foto-vao {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-foto {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
}
</style>
