<template>
    <div class="container-formulario">
        <!-- Navegação entre as etapas -->
        <div class="mb-4">
            <div class="container-cabecalho justify-end flex mb-4">
                <router-link to="/obras" class="text-xl text-corBase font-bold">
                    <i class="fas fa-chevron-left"></i>
                </router-link>
            </div>
            <div class="etapas-navegacao">
                <button :class="{ 'ativo': etapaAtual === 1 }" @click="etapaAtual = 1">Obra</button>
                <button :class="{ 'ativo': etapaAtual === 2 }" @click="etapaAtual = 2">Endereço</button>
                <button :class="{ 'ativo': etapaAtual === 3 }" @click="etapaAtual = 3">Stakeholders</button>
                <button :class="{ 'ativo': etapaAtual === 4 }" @click="etapaAtual = 4"
                    v-if="isadmin === true">Financeiro</button>
            </div>
        </div>

        <!-- Formulário de criação da obra -->
        <form @submit.prevent="salvarObra">
            <!-- Seção de Obra -->
            <div v-if="etapaAtual === 1">
                <Obra :obra="obra" @voltarEtapa="voltarEtapa" @proximaEtapa="proximaEtapa" />
            </div>

            <!-- Seção de Endereço -->
            <div v-if="etapaAtual === 2">
                <Endereco :obra="obra" @voltarEtapa="voltarEtapa" @proximaEtapa="proximaEtapa" />
            </div>

            <!-- Seção de Stakeholders -->
            <div v-if="etapaAtual === 3">
                <ListarStakeholders :obra="obra" @voltarEtapa="voltarEtapa"
                    @salvarStakeholdersObrigatorios="atualizarStakeholdersObrigatorios" />
            </div>

            <!-- Seção de Financeiro -->
            <div v-if="etapaAtual === 4">
                <Financeiro :obra="obra" @voltarEtapa="voltarEtapa" @salvarObra="salvarObra" />
            </div>
        </form>
    </div>
    <SucessoRapido v-if="sucesso === true" :mensagem="sucessoMessage" />
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import SucessoRapido from '@/components/toasts/toast_sucesso/SucessoRapido.vue';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';
import Obra from './EtapasCriacaoObra/Obra.vue';
import Endereco from './EtapasCriacaoObra/Endereco.vue';
import ListarStakeholders from './EtapasCriacaoObra/ListarStakeholders.vue';
import Financeiro from './EtapasCriacaoObra/Financeiro.vue';
import axios from 'axios';

export default {
    name: 'CriarNovaObra',
    components: {
        SucessoRapido,
        ErroRapido,
        Obra,
        Endereco,
        ListarStakeholders,
        Financeiro,
    },
    data() {
        return {
            sucesso: false,
            erro: false,
            erroMessage: '',
            sucessoMessage: '',
            etapaAtual: 1,
            obra: {
                descricao: '',
                imagem: {},
                endereco: '',
                numero: '',
                apartamento: '',
                bloco: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: '',
                cep: '',
                localizacao: '',
                status_obra: '',
                comissao: 0,
                valor_rt: 0,
                cliente: {},
                patrocinador: {},
                responsavel: {},
                stakeholders: [],
            },
            id_obra_criada: ''
        };
    },

    computed: {
        isadmin() {
            return this.$store.state.isAdminUser;
        },
    },
    methods: {
        proximaEtapa() {
            if (this.etapaAtual < 4) {
                this.etapaAtual++;
            }
        },
        voltarEtapa() {
            if (this.etapaAtual > 1) {
                this.etapaAtual--;
            }
        },

        atualizarStakeholdersObrigatorios(stakeholdersObrigatorios) {
            this.obra.cliente = stakeholdersObrigatorios.cliente;
            this.obra.patrocinador = stakeholdersObrigatorios.patrocinador;
            this.obra.responsavel = stakeholdersObrigatorios.responsavel;
        },

        async salvarObra() {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.post(
                    'obra/',
                    {
                        ...this.obra,
                        cliente: this.obra.cliente.pessoa.id,
                        patrocinador: this.obra.patrocinador.pessoa.id,
                        responsavel: this.obra.responsavel.pessoa.id,
                    },
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );

                // Verifica se tem algum stakeholder além dos 3 obrigatórios
                if (this.obra.stakeholders.length >= 4) {
                    this.id_obra_criada = response.data.id
                    this.salvarPessoasAlocadas(this.id_obra_criada)
                } else {
                    this.sucesso = true;
                    this.sucessoMessage = 'Obra criada com sucesso.'
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = ''
                        this.$router.push('/obras');
                    }, 3000);
                }
            } catch (error) {
                console.error('Erro ao criar a obra:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao criar a Obra.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            }
        },

        async salvarPessoasAlocadas(id_obra) {
            const token = this.$store.state.token;
            await this.$store.dispatch('fetchURLrequest');
            const stakeholders = this.obra.stakeholders
                .filter(stakeholder => !['Cliente', 'Patrocinador', 'Responsável'].includes(stakeholder.funcao));

            try {
                for (const stakeholder of stakeholders) {
                    const response = await axios.post(
                        `obra/pessoa-alocada/`,
                        {
                            obra: id_obra,
                            pessoa: stakeholder.pessoa.id,
                            responsabilidade: stakeholder.funcao.id
                        },
                        {
                            headers: {
                                Authorization: `Token ${token}`,
                            },
                        }
                    );

                    this.sucesso = true;
                    this.sucessoMessage = 'Stakeholders alocados com sucesso.'
                    setTimeout(() => {
                        this.sucesso = false;
                        this.sucessoMessage = ''
                        this.$router.push('/obras');
                    }, 3000);
                }

            } catch (error) {
                console.error('Erro ao criar pessoas alocadas:', error);
                this.erro = true;
                this.erroMessage = 'Ocorreu um erro ao alocar pessoas.';
                setTimeout(() => {
                    this.erro = false;
                    this.erroMessage = '';
                }, 3000);
            }
        },
    },
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    /* Espaço entre os botões */
    overflow-x: auto;
    /* Permite rolagem horizontal se necessário */
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>