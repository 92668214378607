<template>
    <div>
        <div v-if="projetos.length > 0">
            <ul class="flex flex-wrap gap-6 justify-center">
                <li v-for="projeto in projetos" :key="projeto.id" class="flex flex-col items-center w-60 p-4 bg-white border border-corBase/40 shadow-md rounded-md">
                    <img src="@/assets/imagens/fotos/janela2folhas.png" alt="Imagem do Projeto" class="w-40 h-40 object-cover mb-4">
                    <p class="text-sm font-medium text-center">{{ projeto.descricao_fixa }}</p>
                    <button class="mt-4 bg-corBarraLateral text-white text-xs px-4 py-2 whitespace-nowrap"
                        @click.prevent="selecionarProjeto(projeto)">
                        Selecionar projeto
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        projetos: {
            type: Array,
            required: true
        },
        titulo: {
            type: String,
            required: true
        },
        abaId: {
            type: String,
            required: true
        },
        nomeDaLinha: {
            type: String,
            required: true
        }
    },
    methods: {
        selecionarProjeto(projeto) {
            this.$emit('projeto-selecionado', { projeto, abaId: this.abaId, nomeDaLinha: this.nomeDaLinha });
        }
    }
};
</script>

<style scoped>

</style>
