<template>

  <div class="w-full">
  <!-- MODAL PARA ADICIONAR NOVA FORMULA AO PROJETO -->
  <div class="flex flex-col gap-4 mt-4 mb-6">
    <h2 class="text-xl font-bold text-corBase">Variáveis do Projeto</h2>
    <modal-formula-projeto :projeto="projeto_id" @buscarFormula="getListaFormulasProjeto"/>
  </div>
  </div>

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-3 py-3">
          #
        </th>
        <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
        </th>
        <th scope="col" class="px-3 py-3">
          Opções
        </th>
        <th class="py-3">
          Medida/Valor
        </th>
        <th class="py-3">
          Valor Minimo
        </th>
        <th scope="col" class="py-3">
          Valor Máximo
        </th>
        <th scope="col" class="px-3 py-3">
          Variável
        </th>
        <th scope="col" class="px-3 py-3">
          Descrição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Obrigatório
        </th>
        <th scope="col" class="py-3">
          Enviar Tempera
        </th>
        <th scope="col" class="py-3">
          Rel. Produção
        </th>
        <th scope="col" class="py-3">
          Rel. Orçamento
        </th>
        <th scope="col" class="py-3">
          Formula Padrão
        </th>
      </tr>
      </thead>
      <draggable 
        :list="lista_variaveis_locais" 
        tag="tbody" 
        handle=".handle"
      >
        <template v-slot:item="{ element, index }">
          <tr :key="index" class="escopo-tabela">
            <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
              <div class="handle cursor-pointer">
                <span>☰</span> <!-- Handle para arrastar -->
                {{ element.index }}
              </div>
            </th>
            <td class="w-4 p-4">
              <div class="selec">
                <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox" v-model="element.selected">
                <label class="sr-only">checkbox</label>
              </div>
            </td>
            <td class="py-2 text-center flex gap-6">
              <button @click.prevent="abrirModal(element)">
                <i class="fa-solid fa-paperclip"></i>
              </button>
              <button @click.prevent="element.edit = true">
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            </td>
            <td class="py-2 text-xs">
              <input type="number" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.medida_valor">
            </td>
            <td class="py-2 text-xs">
              <input type="number" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.valor_min">
            </td>
            <td class="py-2 text-xs">
              <input type="number" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.valor_max">
            </td>
            <td class="px-3 py-2 text-xs" v-if="!element.edit">
              {{ element.variavel }}
            </td>
            <td class="py-2 text-xs" v-else>
              <input 
                type="text" 
                class="w-full p-1 text-corBase border border-corBase/40" 
                v-model="element.variavel"
                @input="forcarPrefixo(element)"
                :placeholder="valorPadrao"
              >
            </td>
            <td class="px-3 w-60 py-2 text-xs" v-if="!element.edit">
              {{ element.descricao }}
            </td>
            <td class="py-2 text-xs" v-else>
              <input 
                type="text" 
                class="w-full p-1 text-corBase border border-corBase/40" 
                v-model="element.descricao"
              >
            </td>
            <td class="py-2 text-xs">
              <input type="text" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.condicao">
            </td>
            <td class="py-2 text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.obrigatorio">
            </td>
            <td class="py-2 text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.tempera">
            </td>
            <td class="py-2 text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.rel_prod">
            </td>
            <td class="py-2 text-xs">
              <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40" v-model="element.rel_orc">
            </td>
            <td class="py-2 text-xs">
              <input class="w-full p-1 text-corBase border border-corBase/40" v-model="element.formula_padrao" type="checkbox">
            </td>
          </tr>
        </template>
      </draggable>
    </table>
    <table class="container-tabela overflow-auto" v-if="novasVariaveis.length">
      <thead class="cabecalho-tabela">
      <tr>
        <th class="py-3">
          Medida/Valor
        </th>
        <th class="py-3">
          Valor Minimo
        </th>
        <th scope="col" class="py-3">
          Valor Máximo
        </th>
        <th scope="col" class="py-3">
          Variável
        </th>
        <th scope="col" class="py-3">
          Descrição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Obrigatório
        </th>
        <th scope="col" class="py-3">
          Enviar Tempera
        </th>
        <th scope="col" class="py-3">
          Rel. Produção
        </th>
        <th scope="col" class="py-3">
          Rel. Orçamento
        </th>
        <th scope="col" class="py-3">
          Formula Padrão
        </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(variavel, index) in novasVariaveis" :key="index" class="escopo-tabela">
          <td class="py-2 text-xs">
            <input 
              type="number"
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="variavel.medida_valor"
              min="1"
              @input="variavel.medida_valor < 0 ? variavel.medida_valor = '' : variavel.medida_valor = variavel.medida_valor"
            >
          </td>
          <td class="py-2 text-xs">
            <input 
              type="number"
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="variavel.valor_min"
              min="1"
              @input="variavel.valor_min < 0 ? variavel.valor_min = '' : variavel.valor_min = variavel.valor_min"
            >
          </td>
          <td class="py-2 text-xs">
            <input 
              type="number"
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="variavel.valor_max"
              min="1"
              @input="variavel.valor_max < 0 ? variavel.valor_max = '' : variavel.valor_max = variavel.valor_max"
            >
          </td>
          <td class="py-2 text-xs">
            <input 
              type="text" 
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="variavel.variavel"
              @input="forcarPrefixo(variavel)"
              :placeholder="valorPadrao"
            >
          </td>
          <td class="py-2 text-xs">
            <input 
              type="text" 
              class="w-full p-1 text-corBase border border-corBase/40"
              v-model="variavel.descricao"
              @input="variavel.descricao = variavel.descricao.toUpperCase()"
            >
          </td>
          <td class="py-2 text-xs">
            <input type="text" class="w-full p-1 text-corBase border border-corBase/40"
                    v-model="variavel.condicao">
          </td>
          <td class="py-2 text-xs">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                    v-model="variavel.obrigatorio">
          </td>
          <td class="py-2 text-xs">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                    v-model="variavel.tempera">
          </td>
          <td class="py-2 text-xs">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                    v-model="variavel.rel_prod">
          </td>
          <td class="py-2 text-xs">
            <input type="checkbox" class="w-full p-1 text-corBase border border-corBase/40"
                    v-model="variavel.rel_orc">
          </td>
          <td class="py-2 text-xs">
            <input 
              class="w-full p-1 text-corBase border border-corBase/40 "
              v-model="variavel.formula_padrao"
              type="checkbox"
            >
          </td>

        </tr>
      </tbody>
    </table>

    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novasVariaveis.length">
      <button 
        class="btn-salvar" 
        @click.prevent="postNovaVariavel"
        :disabled="!botaoAdicionarHabilitado"
      >
        Adicionar    
      </button>
    </div>
    

    <div class="flex justify-center" v-if="!novasVariaveis.length">
      <button class="text-ativo" @click.prevent="adicionarVariavel">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarVariaveis(lista_variaveis_locais)">Salvar</button>
  </div>

  <ModalOpcoesVariaveis
    :abrir="modal_opcoes"
    :variavel="variavelAtual"
    :opcoesVariavel="opcoesVariavel"
    @fechar="fechar"
    @getListaOpcoesVariavel="getListaOpcoesVariavel"
  />

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />

</template>

<script>
import axios from 'axios'
import ModalOpcoesVariaveis from '@/components/midais/alterar_projeto/ModalOpcoesVariaveis.vue';
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue';
import draggable from 'vuedraggable'

export default {
  name: "VariaveisProjeto",

  props: ['lista_variaveis_locais', 'projeto_id'],

  data() {
    return {
      novasVariaveis: [],
      opcoesVariavel: [],
      modal_opcoes: false,
      modal_confirmacao: false,
      variavelAtual: {},
      valorPadrao: 'VP',

      checkAll: false,
    }
  },

  created() {
    this.getListaOpcoesVariavel()
  },

  components: {
    ModalOpcoesVariaveis,
    MensagemConfirmacaoExclusao,
    draggable
  },

  computed: {
    checkSome() {
      return this.lista_variaveis_locais.some(variavel => variavel.selected)
    },

    botaoAdicionarHabilitado() {
      return this.novasVariaveis.every(variavel =>
        variavel.variavel !== this.valorPadrao &&
        // #TODO: validações suspendidas pelo Raul 
        // !isNaN(variavel.medida_valor) && variavel.medida_valor !== null && variavel.medida_valor >= 1 &&
        // !isNaN(variavel.valor_min) && variavel.valor_min !== null && variavel.valor_min >= 1 &&
        // !isNaN(variavel.valor_max) && variavel.valor_max !== null && variavel.valor_max >= 1 &&
        variavel.descricao.trim() !== ''
      );
    },
  },

  methods: {
    atualizarVariaveis(variaveisAAtualizar) {
      variaveisAAtualizar.forEach((variavel, index) => {
        variavel.index = index + 1
      })

      this.$emit('atualizarVariaveis', variaveisAAtualizar)
    },

    fechar() {
      this.modal_opcoes = false
    },

    feharModalConfirmacao() {
      this.modal_confirmacao = false
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async excluirSelecionados() {
      if(this.checkAll) {
        await this.deletarVariavel(this.lista_variaveis_locais)
        this.modal_confirmacao = false
        this.checkAll = false
        this.$emit('getListaVariaveisLocais')

        return
      }
      const variaveisADeletar = this.lista_variaveis_locais.filter((variavel) => {
        if(variavel.selected){
          return variavel
        }
      })

      const variaveisAAtualizar = this.lista_variaveis_locais.filter(variavel => 
        !variaveisADeletar.some(deletar => deletar.id === variavel.id)
      )

      await this.deletarVariavel(variaveisADeletar)
      this.modal_confirmacao = false
      this.atualizarVariaveis(variaveisAAtualizar)
    },

    abrirModal(variavelSelecionada) {
      this.variavelAtual = variavelSelecionada
      this.modal_opcoes = true
    },

    forcarPrefixo(variavel) {
      const prefixo = this.valorPadrao;
      if (!variavel.variavel.startsWith(prefixo)) {
        variavel.variavel = prefixo + variavel.variavel.slice(prefixo.length);
      }
      // Converte o restante para maiúsculas
      variavel.variavel = variavel.variavel.toUpperCase();
    },

    adicionarVariavel() {
      this.novasVariaveis.push({
        projeto: this.projeto_id,
        medida_valor: '',
        valor_min: '',
        valor_max: '',
        variavel: this.valorPadrao,
        descricao: '',
        condicao: '',
        obrigatorio: false,
        enviar_tempera: false,
        rel_producao: false,
        rel_orcamento: false,
        formula_padrao: '',
        index: this.lista_variaveis_locais.length + 1
      })
    },

    async postNovaVariavel() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const novasVariaveisPromisses = []

      for(const novaVariavel of this.novasVariaveis){

        novasVariaveisPromisses.push(
          axios.post('projeto/variavel-local/', novaVariavel, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(novasVariaveisPromisses)

      this.novasVariaveis = []
      this.$emit('getListaVariaveisLocais')

    },

    async deletarVariavel(variaveisADeletar) {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const variaveisADeletarPromisses = []

        for(const variavel of variaveisADeletar){

          variaveisADeletarPromisses.push(
            axios.delete(`projeto/variavel-local/${variavel.id}/`, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }

        await Promise.all(variaveisADeletarPromisses)

      }catch(e){
        console.error(e)
      }
    },

    async getListaOpcoesVariavel() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')

        const response = await axios.get(`projeto/opcao-variavel-local/?projeto=${this.projeto_id}`, {
          headers: {
            Authorization: `Token ${token}`
          }
        })

        this.opcoesVariavel = response.data.results
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss">
  .btn-salvar {
    position: relative;
    transition: opacity 0.3s ease, cursor 0.3s ease;

    &:disabled {
      opacity: 0.6;  // Deixa o botão opaco
      cursor: not-allowed;  // Muda o cursor para bloqueado

      // Estilização para a mensagem flutuante
      &::after {
        content: 'Preencha todos os campos obrigatórios'; // Mensagem flutuante
        position: absolute;
        top: -35px;  // Posição acima do botão
        left: -10%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.75);  // Fundo da mensagem
        color: white;  // Cor da mensagem
        padding: 5px 10px;  // Espaçamento interno
        border-radius: 4px;  // Bordas arredondadas
        font-size: 12px;  // Tamanho da fonte
        white-space: nowrap;
        opacity: 0;  // Inicialmente invisível
        transition: opacity 0.3s ease;
        pointer-events: none;  // Previne que a mensagem receba cliques
      }

      &:hover::after {
        opacity: 1;  // Torna a mensagem visível ao passar o mouse
      }
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Remove os botões no Firefox */
  }
</style>
