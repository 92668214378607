<template>
    <div class="container-formulario">

        <!-- Cabeçalho lista de obras -->
        <div class="container-cabecalho flex justify-between mb-4">
            <div>
                <h1 class="text-xl text-corBase font-bold">Obras</h1>
            </div>

            <router-link to="/dashboard" class="text-xl text-corBase font-bold">
                <i class="fas fa-chevron-left"></i>
            </router-link>
        </div>

        <!-- Filtros e Ações -->
        <div class="flex gap-4 mb-6 items-end w-[100%]">
            <div class="w-full">
                <label class="text-corBase">Cliente</label>
                <input v-model="clienteFilter" v-on:input="clienteFilter = clienteFilter.toUpperCase()" class="w-full z-10" type="text">
            </div>
            <div>
                <button class="btn-add-fisica" @click="getObras(1)">Pesquisar</button>
            </div>
            <div>
                <button @click="$router.push(`/criar-obra`)" class="btn-add-fisica whitespace-nowrap">
                    Nova Obra
                </button>
            </div>
        </div>

        <!-- Listagem das obras -->
        <div class="container-modelo-tabela">
            <table class="container-tabela overflow-auto">
                <thead class="cabecalho-tabela">
                    <tr>
                        <th class="py-2 px-4 bg-gray-200 border-b">Ações</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Imagem</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Descrição</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Cliente</th>
                        <th class="py-2 px-4 bg-gray-200 border-b">Localização</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(obra, index) in obras" :key="obra.id">
                        <tr class="escopo-tabela" @click="selecionarObra(obra)">
                            <td class="py-2 px-4 flex gap-2">
                                <button @click="deleteObra(obra.id)" class="text-gray-500" title="Excluir Obra">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button @click="this.$router.push(`/obra/${obra.id}`)" class="text-gray-500"
                                    title="Editar Obra">
                                    <i class="fas fa-paperclip"></i>
                                </button>
                            </td>
                            <td class="py-2 px-4 border-b">
                                <!-- Verifica se a Obra tem uma imagem -->
                                <img v-if="obra.imagem && obra.imagem.url_documento" :src="obra.imagem.url_documento"
                                    alt="Foto da Obra" class="foto-obra" />
                                    <!-- @click="abrirModal(obra.imagem.url_documento)" -->

                                <!-- Se não houver imagem, exibe uma mensagem alternativa -->
                                <span v-else class="text-gray-500">Sem foto disponível</span>
                            </td>
                            <td class="py-2 px-4 border-b">{{ obra.descricao }}</td>
                            <td class="py-2 px-4 border-b">{{ obra.cliente.nome_razao }}</td>
                            <td class="py-2 px-4 border-b">
                                <a :href="obra.localizacao" target="_blank" class="text-blue-600">Localização</a>
                            </td>
                        </tr>

                        <!-- Linha para obra selecionada -->
                        <tr v-if="obraSelecionada.id === obra.id" class="bg-gray-200">
                            <td colspan="5" class="py-2 px-4">
                                <div>
                                    <strong>Descrição:</strong> {{ obraSelecionada.descricao }} <br />
                                    <strong>Cliente:</strong> {{ obraSelecionada.cliente.nome_razao }} - {{
                                        formataTelefonePessoa(obraSelecionada.cliente.pessoa_fisica.telefone_pessoa[0].ddd,
                                            obraSelecionada.cliente.pessoa_fisica.telefone_pessoa[0].telefone) }}
                                    <br />
                                    <strong>Endereço:</strong> {{ obraSelecionada.endereco }}, nº {{
                                        obraSelecionada.numero }}, {{ obraSelecionada.bairro }}, {{
                                        obraSelecionada.cidade
                                    }} - {{ obraSelecionada.estado }} <br />
                                    <strong>Localização: </strong>
                                    <a :href="obraSelecionada.localizacao" target="_blank" class="text-blue-600">Clique
                                        aqui</a> <br />
                                    <strong>Responsável:</strong> {{ obraSelecionada.responsavel.nome_razao }} - {{
                                        formataTelefonePessoa(obraSelecionada.responsavel.pessoa_fisica.telefone_pessoa[0].ddd,
                                            obraSelecionada.responsavel.pessoa_fisica.telefone_pessoa[0].telefone) }} <br />
                                    <strong>Patrocinador:</strong> {{ obraSelecionada.patrocinador.nome_razao }} -
                                    {{
                                        formataTelefonePessoa(obraSelecionada.patrocinador.pessoa_fisica.telefone_pessoa[0].ddd,
                                            obraSelecionada.patrocinador.pessoa_fisica.telefone_pessoa[0].telefone) }}
                                    <br />
                                    <strong>Status da obra:</strong> {{
                                        replaceSiglaStatusObra(obraSelecionada.status_obra) }}
                                </div>
                                <div class="flex">
                                    <strong class="pr-2">Stakeholders:</strong>
                                    <ul v-if="stakeholders.length > 0">
                                        <li v-for="stakeholder in stakeholders" :key="stakeholder.id">
                                            {{ stakeholder.pessoa.nome_razao }} - {{
                                                stakeholder.responsabilidade.descricao }} - {{
                                                formataTelefonePessoa(stakeholder.pessoa.pessoa_fisica.telefone_pessoa[0].ddd,
                                                    stakeholder.pessoa.pessoa_fisica.telefone_pessoa[0].telefone) }}
                                        </li>
                                    </ul>
                                    <p v-else>Sem stakeholders definidos.</p>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <!-- Botões da paginação -->
        <Pagination v-if="obras.length > 0" :offset="paginaAtual" :total="countItens" :limit="20"
            @change-page="mudarPagina" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import PreviewImage from "@/components/midais/ged/PreviewImage";
import FormatarTelefonePessoa from "@/utils/FormatarTelefonePessoa";
import Pagination from "@/utils/Pagination.vue";

export default {
    name: "ListaObras",

    components: {
        Select2,
        PreviewImage,
        Pagination
    },

    data() {
        return {
            obras: [],
            stakeholders: [],
            obraSelecionada: {},
            paginaAtual: 1,
            countItens: '',
            clienteFilter: '',
        };
    },

    created() {
        this.getObras(1)
    },

    methods: {
        selecionarObra(obra) {
            this.stakeholders = []
            this.obraSelecionada = obra;
            this.getPessoasAlocadas(this.obraSelecionada.id)
        },

        replaceSiglaStatusObra(status) {
            return status
                .replace('PE', 'Pendente')
                .replace('CA', 'Cancelado')
                .replace('PL', 'Planejamento')
                .replace('CR', 'Construção Inicial')
                .replace('AC', 'Acabamento')
                .replace('RF', 'Reforma')
                .replace('DM', 'Demolição')
                .replace('OR', 'Orçado')
                .replace('EX', 'Execução')
                .replace('CO', 'Concluído')
        },

        formataTelefonePessoa(ddd, telefone) {
            return FormatarTelefonePessoa(ddd, telefone)
        },

        async getObras(pagina) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                this.$store.dispatch("setLoading", true);
                const response = await axios.get(`obra/?page=${pagina}&cliente=${this.clienteFilter}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                });
                this.obras = response.data.results
                this.countItens = response.data.count
                this.$store.dispatch("setLoading", false);
            } catch (error) {
                console.error(error);
            }
        },

        async getPessoasAlocadas(obra_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.get(`obra/pessoa-alocada/?obra=${obra_id}`, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })
                this.stakeholders = response.data.results
                console.log(this.stakeholders)
            } catch (error) {
                console.error(error)
            }
        },

        async deleteObra(obra_id) {
            const token = await this.$store.dispatch('getAuthTokenCookie');
            await this.$store.dispatch('fetchURLrequest');
            try {
                const response = await axios.patch(`obra/obra/${obra_id}/`, { ativo: 'False' }, {
                    headers: {
                        Authorization: `Token ${token}`
                    }
                })

                this.getObras(1)
            } catch (error) {
                console.error(error)
            }
        },

        mudarPagina(pagina) {
            this.paginaAtual = pagina
            this.getObras(pagina)
        },
    }
}
</script>

<style scoped>
.container-cards-produtos {
    @apply flex flex-wrap px-3 py-3
}

input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.foto-obra {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}
</style>
