<template>

  <div class="">

  </div>

  <preview-image 
    v-if="preview_imagem" 
    @closed="closed_preview_image" 
    :imagem="preview_imagem"
  />

  <div class="container-modelo-tabela">
    <table class="container-tabela overflow-auto">
      <thead class="cabecalho-tabela">
      <tr>
        <th scope="col" class="px-3 py-3">
          #
        </th>
        <th scope="col" class="p-4">
          <div class="flex items-center">
            <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

            <label class="sr-only">checkbox</label>
          </div>
        </th>
        <th class="px-3 py-3">
          Perfil
        </th>
        <th class="px-3 py-3">
          Foto
        </th>
        <th scope="col" class="px-3 py-3">
          Descrição
        </th>
        <th scope="col" class="px-3 py-3">
          Peso
        </th>
        <th scope="col" class="py-3">
          Corte
        </th>
        <th scope="col" class="py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class="py-3">
          Medida/Fórmula
        </th>
        <th scope="col" class="py-3">
          Posição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
        <th scope="col" class="py-3">
          Fase Montagem
        </th>
        <th scope="col" class="py-3">
          Aplicação
        </th>
      </tr>
      </thead>
      <draggable 
        :list="perfis"
        tag="tbody" 
        handle=".handle"
      >
      <template v-slot:item="{ element, index }">
        <tr :key="index" class="escopo-tabela">
          <th scope="row" class="px-3 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            <div class="handle cursor-pointer">
              <span>☰</span> <!-- Handle para arrastar -->
              {{ element.index }}
            </div>
          </th>
          <td class="w-4 p-4">
            <div class="selec">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                      v-model="element.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <td class="px-3 py-2 text-xs">
            <v-select
              v-model="element.perfil_id"
              :options="perfisPesquisados"
              label="codigo_base"
              class="bg-white w-32"
              :clearable="false"
              @input="(s) => buscarPerfis(s.target.value)"
              @keydown.enter="atualizarUnicoPerfil(element.perfil_id, element.index)"
            >
              <template v-slot:option="option">
                <div @click.prevent="atualizarUnicoPerfil(option, element.index)">
                  <span class="font-bold">
                    {{ option.codigo_base }}
                  </span> - 
                  {{ option.nome_base }}
                </div>
              </template>
            </v-select>
          </td>
          <td class="px-3 py-2 text-xs">
              <img 
                v-if="element.perfil_id.imagem"
                :src="element.perfil_id.imagem.url_documento"
                class="w-28 cursor-pointer"
                @click="openPreviewImage(element.perfil_id.imagem.url_documento)"
              >
  
              <img 
                v-else
                src="https://managerglass.s3.amazonaws.com/media/public/b7144d8c-4a5c-4c56-a9bd-924275d6c55d.png"
                class="w-28"
              >
          </td>
          <td class="px-3 w-60 py-2 text-xs">
            {{ element.perfil_id.nome_base }}
          </td>
          <td class="px-3 py-2 text-xs">
            {{ element.perfil_id.peso_base }}
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.corte">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.qtd_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.medida_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.posicao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.condicao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.observacao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" :value="element.fase_esquadria ? element.fase_esquadria.descricao : ''">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="element.aplicacao">
          </td>
        </tr>
      </template>
    </draggable>
    </table>

    <table class="container-tabela overflow-auto" v-if="novosPerfis.length">
      <thead class="cabecalho-tabela">
        <th class="w-[28vw] px-6">
          Busque o Perfil:
        </th>
        <th scope="col" class="py-3">
          Corte
        </th>
        <th scope="col" class="py-3">
          QTDE/Fórmula
        </th>
        <th scope="col" class="py-3">
          Medida/Fórmula
        </th>
        <th scope="col" class="py-3">
          Posição
        </th>
        <th scope="col" class="py-3">
          Condição
        </th>
        <th scope="col" class="py-3">
          Observação
        </th>
        <th scope="col" class="py-3">
          Fase Montagem
        </th>
        <th scope="col" class="py-3">
          Aplicação
        </th>
      </thead>
      <tbody>
        <tr v-for="(novoPerfil, index) in novosPerfis" :key="index" class="escopo-tabela">
          <td class="px-6 py-2 text-xs">
            <Search
              v-model="perfilSelecionado"
              :options="perfisPesquisados"
              :labelKey="getLabelKey"
              valueKey="id"
              :nameKey="getNameKey"
              @select="onSelect"
              @shouldSearch="buscarPerfis"
              class="w-[25vw]"
            />
          </td>
          <td class="py-2 text-xs w-[8vw]">
            <input class="input-campos" v-model="novoPerfil.corte" list="corte">
            <datalist id="corte">
              <option value="45/45"></option>
              <option value="45/90"></option>
              <option value="90/45"></option>
              <option value="90/90"></option>
            </datalist>
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.qtd_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.medida_formula">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.posicao" list="posicao">
            <datalist id="posicao">
              <option value="H"></option>
              <option value="L"></option>
              <option value="V"></option>
            </datalist>
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.condicao">
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.observacao">
          </td>
          <td class="py-2 text-xs">
            <select v-model="novoPerfil.fase_esquadria" class="input-campos">
              <option 
                v-for="fase in fasesEsquadria" 
                :value="fase"
                :key="fase.id"
              >
                {{ fase.descricao }}
              </option>
            </select>
          </td>
          <td class="py-2 text-xs">
            <input class="input-campos" v-model="novoPerfil.aplicacao" list="aplicacao">
            <datalist id="aplicacao">
              <option value="A Definir"></option>
              <option value="Obra"></option>
              <option value="Fabrica"></option>
              <option value="Embalagem"></option>
              <option value="Expedicao"></option>
            </datalist>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="flex w-full justify-end itens-center mt-6 px-4 mb-5" v-if="novosPerfis.length">
      <button 
        class="btn-salvar" 
        @click.prevent="postNovoPerifl">
          Adicionar
      </button>
    </div>

    <div class="flex justify-center" v-if="!novosPerfis.length">
      <button class="text-ativo" @click.prevent="adicionarPerfil">
        <i class="fas fa-plus-circle"></i>
      </button>
    </div>
  </div>
  <div class="flex w-full justify-end itens-center mt-6">
    <button
      class="btn-cancelar" 
      @click.prevent="modal_confirmacao = true"
      v-if="checkSome || checkAll"
    >
      Excluir Selecionados
    </button>
    <button class="btn-cancelar" @click.prevent="getListaPerfis">Cancelar</button>
    <button class="btn-salvar" @click.prevent="atualizarPerfis(perfis)">Salvar</button>
  </div>

  <MensagemConfirmacaoExclusao
    mensagem="Deseja mesmo excluir os itens selecionados?"
    :abrir="modal_confirmacao"
    @feharModal="feharModalConfirmacao"
    @confirmarExclusao="excluirSelecionados"
  />

</template>

<script>
import axios from 'axios'
import Search from '@/utils/Search.vue'
import MensagemConfirmacaoExclusao from '@/components/toasts/toast_notificacao/MensagemConfirmacaoExclusao.vue'
import draggable from 'vuedraggable'
import Search2 from '@/utils/Search2.vue'
import vSelect from "vue-select";
import PreviewImage from '@/components/midais/ged/PreviewImage.vue'

export default {
  name: "PerfilProjeto",

  props: ['perfis', 'projeto_id'],

  data() {
    return {
      novosPerfis: [],
      perfilSelecionado: [],
      perfisPesquisados: [],
      fasesEsquadria: [],
      preview_imagem: '',

      checkAll: false,
      modal_confirmacao: false,
    }
  },

  components: {
    Search,
    MensagemConfirmacaoExclusao,
    draggable,
    Search2,
    vSelect,
    PreviewImage
  },

  created() {
    this.buscaFases()
  },

  computed: {
    checkSome() {
      return this.perfis.some(perfil => perfil.selected)
    }
  },

  methods: {
    closed_preview_image() {
      this.preview_imagem = ''
    },

    openPreviewImage(url) {
      this.preview_imagem = url
    },

    atualizarUnicoPerfil(perfil, index){
      const perfilProjetoAAtualizarId =  this.perfis[index - 1].id

      this.perfisPesquisados = []
      this.$emit('atualizarUnicoPerfil', perfil.id, perfilProjetoAAtualizarId)

    },

    atualizarPerfis(perfisAAtualizar) {
      perfisAAtualizar.forEach((perfil, index) => {
        perfil.index = index + 1
        perfil.fase_esquadria = perfil.fase_esquadria ? perfil.fase_esquadria.id : ''
      })

      this.$emit('atualizarPerfis', perfisAAtualizar)
    },

    getLabelKey(option) {
      return option.codigo_base ? option.codigo_base : ''
    },

    getNameKey(option) {
      return option.nome_base ? option.nome_base : ''
    },

    onSelect(perfil) {
      this.perfilSelecionado = perfil
    },

    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.getElementsByClassName('checkbox');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    excluirSelecionados() {
      if(this.checkAll) {
        this.deletarPerfis(this.perfis)
        this.modal_confirmacao = false
        this.checkAll = false
        return
      }
      const perfisADeletar = this.perfis.filter((perfil) => {
        if(perfil.selected){
          return perfil
        }
      })

      const perfisAAtualizar = this.perfis.filter(perfil => 
        !perfisADeletar.some(deletar => deletar.id === perfil.id)
      )

      this.deletarPerfis(perfisADeletar)
      this.modal_confirmacao = false
      this.atualizarPerfis(perfisAAtualizar)
    },

    async deletarPerfis(perfisADeletar) {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      const perfisADeletarPromisses = []

      for(const perfil of perfisADeletar){

        perfisADeletarPromisses.push(
          axios.delete(`projeto/projeto-perfil/${perfil.id}/`, {
            headers: {
              Authorization: `Token ${token}`
            }
          })
        )
      }

      await Promise.all(perfisADeletarPromisses)

      this.$emit('getListaPerfisProjeto')
    },

    adicionarPerfil() {
      this.novosPerfis.push({
        projeto_id: this.projeto_id,
        perfil_id: '',
        corte: '',
        qtd_formula: '',
        medida_formula: '',
        posicao: '',
        condicao: '',
        observacao: '',
        index: this.perfis.length + 1
      })
    },

    async buscaFases() {
      const token = await this.$store.dispatch('getAuthTokenCookie');
      await this.$store.dispatch('fetchURLrequest')

      try {
        const response = await axios.get(`projeto/fase-esquadria/?page_size=9999`, {
          headers: {
            Authorization: `Token ${token}`
          }
        });
        this.fasesEsquadria = response.data.results;
      } catch (error) {
        console.error(error)
      }
    },

    async buscarPerfis(searchQuery) {
      if (searchQuery.length > 2) {
        try {
          const token = await this.$store.dispatch('getAuthTokenCookie');
          await this.$store.dispatch('fetchURLrequest')

          const response = await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${searchQuery}&page_size=9999`, {
            headers: {
              Authorization: `Token ${token}`
            }
          });
          this.perfisPesquisados = response.data.results;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.perfisPesquisados = [];
      }
    }, 

    async postNovoPerifl() {
      try {
        const token = await this.$store.dispatch('getAuthTokenCookie');
        await this.$store.dispatch('fetchURLrequest')
  
        const novosPerfisPromisses = []
  
        for(const novoPerfil of this.novosPerfis){
          novoPerfil.perfil_id = this.perfilSelecionado.id
          novoPerfil.fase_esquadria = novoPerfil.fase_esquadria.id
  
          novosPerfisPromisses.push(
            axios.post('projeto/projeto-perfil/', novoPerfil, {
              headers: {
                Authorization: `Token ${token}`
              }
            })
          )
        }
  
        await Promise.all(novosPerfisPromisses)
  
        this.novosPerfis = []
        this.perfisPesquisados = []
        this.$emit('getListaPerfisProjeto')
      } catch (error) {
        console.error(error)
      }

    }
  }
}
</script>