<template>

  <!-- Lista de pessoas até 768 pixels-->
  <div class="container-formulario">
    <div class="container-cabecalho flex justify-between mb-4">
      <div>
        <h1 class="text-xl text-corBase font-bold">Produtos Por Fornecedor</h1>
      </div>

      <router-link to="/produtos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>

    </div>
    <div class="flex items-end mb-6 gap-8">
      <div>
        <label class="text-xl text-corBase">Tipo do Produto:</label>
        <select class="input-form-chapa" v-model="tipo_produto">
          <option value="Perfil">Perfil</option>
          <option value="Chapa">Chapa</option>
          <option value="Vidro">Vidro</option>
          <option value="Uso Consumo">Uso Consumo</option>
          <option value="Componente">Componente</option>
          <option value="Serviço">Serviço</option>
        </select>
      </div>
      <div>
        <label class="text-xl text-corBase">Codígo Fornecedor:</label>
        <input type="text" class="input-form-chapa" v-model="codigo_fornecedor">
      </div>
      <div>
        <label class="text-xl text-corBase">Nome:</label>
        <input type="text" class="input-form-chapa" v-model="nome_produto">
      </div>

      <div>
        <button class="bg-corBase hover:bg-screenCor border border-screenCor hover:border-corBase
  text-white hover:text-corBase py-2 px-4" @click="getListaProdutos">Pesquisar
        </button>
      </div>
      <div>
        <button @click="$router.push(`/produto-por-fornecedor/cadastro`)" class="bg-ativo hover:bg-screenCor border border-screenCor hover:border-ativo
  text-white hover:text-ativo py-2 px-4 ">Adicionar
        </button>
      </div>

    </div>

    <!-- Container da exibiçao da lista de pessoas -->
    <div class="container-modelo-tabela">
      <table class="container-tabela overflow-auto">
        <thead class="cabecalho-tabela">
        <tr>
          <th scope="col" class="p-4">
            <div class="flex items-center">
              <input type="checkbox" class="checkbox" v-model="checkAll" @change="selectAll">

              <label class="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            #
          </th>
          <th class="px-6 py-3">
            COD. Base
          </th>
          <th class="px-6 py-3">
            COD. Fornecedor
          </th>
          <th class="px-6 py-3">
            Descrição
          </th>
          <th class="px-6 py-3">
            Quantidade
          </th>
          <th v-if="isadmin === true" scope="col" class="px-6 py-3">
            Status
          </th>
          <th v-if="isadmin === true" scope="col" class="px-6 py-3">
            Ação
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(produto, index) in lista_produto_fornecedor" :key="index" class="escopo-tabela">

          <td class="w-4 p-4">
            <div class="flex items-center">
              <input :id="'checkbox-table-' + (index + 2)" type="checkbox" class="checkbox"
                     v-model="produto.selected">
              <label class="sr-only">checkbox</label>
            </div>
          </td>
          <th scope="row" class="px-6 py-2 font-medium text-xs text-gray-900 whitespace-nowrap dark:text-white">
            {{ index + 1 }}
          </th>
          <td class="px-6 py-2 text-xs">
            {{ produto.produto_base.codigo_base }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ produto.codigo_fornecedor }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ produto.nome_especifico }}
          </td>
          <td class="px-6 py-2 text-xs">
            {{ produto.quantidade }}
          </td>

          <template v-if="produto.ativo === true && isadmin === true">
            <td class="px-6 py-2 text-xs">
              <i class="fa-solid fa-circle-check  text-ativo ml-3"></i>
            </td>
          </template>
          <template v-if="produto.ativo === false && isadmin === true">
            <td class="px-6 py-2">
              <i class="fa-solid fa-circle-xmark text-inativo ml-3"></i>
            </td>
          </template>

          <td class="px-6 py-2 text-xs" v-if="isadmin === true">
            <editar-produto-fornecedor :produto="produto" :paginaAtual="paginaAtual"></editar-produto-fornecedor>
          </td>

        </tr>
        </tbody>
      </table>
      <div class="paginacao">
        <button @click.prevent="paginaAnterior" class="btn-paginacao">
          <i class="fas fa-chevron-left"></i>
        </button>
        <span v-for="pagina in paginasExibidas" :key="pagina">
          <button @click.prevent="irParaPagina(pagina)"
                  :class="{ 'btn-paginacao': true, 'pagina-atual': pagina === paginaAtual }">
            {{ pagina }}
          </button>
        </span>
        <button @click.prevent="proximaPagina" class="btn-paginacao">
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>


</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import EditarProdutoFornecedor from "@/components/midais/Editar/produtos/EditarProdutoFornecedor";

export default {
  name: "ListaProdutoPorFornecedor",

  components: {
    EditarProdutoFornecedor,
    Select2
  },

  data() {
    return {
      checkAll: false,
      // lista usada para salvar o retorno da requisição do get
      lista_produto_fornecedor: [],
      // lista usada para salvar esse retorno depois de dividido
      listaProdutoFornecedorPaginada: [],
      // varival que inicializa o numero de paginas
      tipo_produto: '',
      nome_produto: '',
      codigo_fornecedor: '',
      paginaAtual: 0,
      next_page: null,
      totalDePaginas: 0,


      error: []
    }
  },

  created() {
  },

  computed: {
    isadmin() {
      return this.$store.state.isAdminUser
    },

    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },

    paginasExibidas() {
      const paginas = [];
      const paginasVisiveis = 5; // Número de páginas visíveis de cada vez

      let inicio = Math.max(1, this.paginaAtual - Math.floor(paginasVisiveis / 2));
      let fim = inicio + paginasVisiveis - 1;

      if (inicio + paginasVisiveis > this.totalDePaginas) {
        inicio = Math.max(1, this.totalDePaginas - paginasVisiveis + 1);
        fim = this.totalDePaginas;
      }

      for (let i = inicio; i <= fim; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },

  methods: {
    selectAll() {
      // Atualiza o estado dos demais checkboxes
      const checkboxes = document.querySelectorAll('.escopo-tabela input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        checkbox.checked = this.checkAll;
      });
    },

    async getListaProdutos() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')
      if (this.paginaAtual === 0) {
        await axios.get(`produtos/produto-por-fornecedor?codigo_fornecedor=${this.codigo_fornecedor}&nome_especifico=${this.nome_produto}&tipo_produto=${this.tipo_produto}&page=1`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_produto_fornecedor = response.data.results
              console.log(this.lista_produto_fornecedor)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)

            })
      } else {
        await axios.get(`produtos/produto-por-fornecedor?codigo_fornecedor=${this.codigo_fornecedor}&nome_especifico=${this.nome_produto}&page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_produto_fornecedor = response.data.results
              console.log(this.lista_produto_fornecedor)
              this.totalDePaginas = response.data.count / 5
              console.log(this.totalDePaginas)

            })
      }

    },

    proximaPagina() {
      this.paginaAtual = this.paginaAtual + 1
      this.getListaProdutos();
    },

    paginaAnterior() {
      this.paginaAtual = this.paginaAtual - 1
      this.getListaProdutos()
    },

    async irParaPagina(pagina) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      console.log(pagina)
      this.paginaAtual = pagina
      await axios.get(`produtos/produto-por-fornecedor?codigo_fornecedor=${this.codigo_fornecedor}&nome_especifico=${this.nome_produto}&page=${this.paginaAtual}`, {
          headers: {
            'Authorization': `Token ${token}`
          }
        })
            .then(response => {
              this.lista_produto_fornecedor = response.data.results
              console.log(this.lista_produto_fornecedor)
              this.totalDePaginas = response.data.count / 5
            })
    }

  }
}
</script>

<style lang="scss">

.pagina-atual {
  background-color: #fff; /* Cor de fundo para a página atual */
  border-color: #164E63 /* Cor do texto para a página atual */
}
</style>