<template>
    <div>
        <div class="form-group">
            <label for="cep">CEP</label>
            <input v-model="obra.cep" type="text" id="cep" @blur="buscarEndereco" maxlength="8"
                placeholder="Digite aqui..." />
        </div>
        <div class="form-group">
            <label for="endereco">Logradouro</label>
            <input v-model="obra.endereco" type="text" id="endereco" placeholder="Digite aqui..." readonly />
        </div>
        <div class="form-group">
            <label for="numero">Número</label>
            <input v-model="obra.numero" type="text" id="numero" placeholder="Digite aqui..." @blur="gerarLinkGoogleMaps" />
        </div>
        <div class="form-group">
            <label for="apartamento">Apartamento</label>
            <input v-model="obra.apartamento" type="text" id="apartamento" placeholder="Digite aqui..." />
        </div>
        <div class="form-group">
            <label for="bloco">Bloco</label>
            <input v-model="obra.bloco" type="text" id="bloco" placeholder="Digite aqui..." />
        </div>
        <div class="form-group">
            <label for="complemento">Complemento</label>
            <input v-model="obra.complemento" type="text" id="complemento" placeholder="Digite aqui..." />
        </div>
        <div class="form-group">
            <label for="bairro">Bairro</label>
            <input v-model="obra.bairro" type="text" id="bairro" placeholder="Digite aqui..." readonly />
        </div>
        <div class="form-group">
            <label for="estado">Estado</label>
            <input v-model="obra.estado" type="text" id="estado" placeholder="Digite aqui..." readonly />
        </div>
        <div class="form-group">
            <label for="cidade">Cidade</label>
            <input v-model="obra.cidade" type="text" id="cidade" placeholder="Digite aqui..." readonly />
        </div>

        <!-- Link do Google Maps -->
        <div v-if="enderecoPreenchido" class="flex items-center justify-center">
            <p class="text-blue-500 mx-2">Google Maps:</p>
            <a :href="obra.localizacao" target="_blank" class="link-maps">
                <i class="fa-solid fa-map-location-dot fa-2x"></i>
            </a>
        </div>

        <div class="flex w-full justify-end itens-center mt-6">
            <button type="button" @click="$emit('voltarEtapa')" class="btn-cancelar">Voltar</button>
            <button type="button" @click="$emit('proximaEtapa')" class="btn-salvar">Avançar</button>
        </div>
    </div>
    <ErroRapido v-if="erro === true" :mensagem="erroMessage" />
</template>

<script>
import axios from 'axios';
import ErroRapido from '@/components/toasts/toast_erro/ErroRapido.vue';

export default {
    name: 'Endereco',
    props: {
        obra: Object
    },
    components: {
        ErroRapido
    },
    data() {
        return {
            erro: false,
            erroMessage: '',
        };
    },
    computed: {
        enderecoPreenchido() {
            return (
                this.obra.endereco !== '' &&
                this.obra.numero !== '' &&
                this.obra.bairro !== '' &&
                this.obra.cidade !== '' &&
                this.obra.estado !== ''
            );
        },

        enderecoCompleto() {
            // Incluindo o número no endereço completo
            return `${this.obra.endereco}, ${this.obra.numero}, ${this.obra.bairro}, ${this.obra.cidade}, ${this.obra.estado}`.trim();
        },
    },
    methods: {
        async buscarEndereco() {
            const cep = this.obra.cep.replace(/\D/g, '');
            if (cep.length === 8) {
                try {
                    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                    if (!response.data.erro) {
                        this.obra.endereco = response.data.logradouro;
                        this.obra.bairro = response.data.bairro;
                        this.obra.cidade = response.data.localidade;
                        this.obra.estado = response.data.uf;
                        this.gerarLinkGoogleMaps();  // Gera o link do Google Maps após buscar o endereço
                    } else {
                        this.exibirErro('Ocorreu um erro ao buscar CEP.');
                    }
                } catch (error) {
                    this.exibirErro('Ocorreu um erro ao buscar CEP.');
                }
            } else {
                this.exibirErro('Ocorreu um erro ao buscar CEP.');
            }
        },

        gerarLinkGoogleMaps() {
            // Verifica se todos os campos estão preenchidos para gerar o link
            if (this.enderecoPreenchido) {
                // Gera o link com o número incluído
                this.obra.localizacao = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.enderecoCompleto)}`;
            }
        },

        exibirErro(mensagem) {
            this.erro = true;
            this.erroMessage = mensagem;
            setTimeout(() => {
                this.erro = false;
                this.erroMessage = '';
            }, 3000);
        }
    }
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    overflow-x: auto;
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.link-maps {
    @apply text-blue-500;
}
</style>
