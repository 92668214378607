<template>

  <div class="container-formulario">

    <!-- TILO DO FORMULARIO + BOTAO DE VOLTAR -->
    <div class="cabecalho-formulario">
      <h1 class="text-xl text-corBase font-bold">Dados do Projeto:</h1>

      <router-link to="/projetos" class="text-xl text-corBase font-bold">
        <i class="fas fa-chevron-left"></i>
      </router-link>
    </div>

    <!-- Escopo do formulario de cadastro mobile-->
    <form @submit.prevent="cadastrarProjeto" enctype="multipart/form-data">
      <div class="w-full mx-auto mt-8">
        <div class="space-y-4">
          <!-- Seção 1 -->
          <div>
            <button @click.prevent="toggleSection(1)"
                    class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Dados do Projeto
            </button>
            <div v-if="activeSection === 1"
                 class="p-4 flex flex-col gap-5 border border-corBase/60 tansition duration-500">
                 
              <!-- Campos de cadastro para a primeira seção -->
              <div class="flex gap-10">
                <div class="flex flex-col w-full">
                  <label class="font-bold text-corBase">Sistemista</label>
                  <v-select 
                    v-model="sistemista"
                    :options="lista_sistemistas"
                    label="text"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                </div>
  
                <div class="flex flex-col w-full">
                  <label class="font-bold text-corBase">Linha/Sistema</label>
                  <v-select 
                    v-model="linha"
                    :options="lista_linhas"
                    label="text"
                    :reduce="(option) => option.id"
                    class="input-disabled"
                    :disabled="!sistemista"
                  >
                  </v-select>
                </div>

                <div class="flex flex-col w-full">
                  <label class="font-bold text-corBase">Modelo</label>
                  <v-select 
                    v-model="tipologia"
                    :options="lista_tipologias"
                    label="text"
                    :reduce="(option) => option.id"
                  >
                  </v-select>
                </div>

              </div>

              <div class="flex gap-12 w-full">
                <div>
                  <label class="font-bold text-corBase">Cod. Projeto</label>
                  <input type="text" class="input-campos" v-model="cod_produto" required>
                </div>
                <div class="w-full">
                  <label class="font-bold text-corBase">Descrição</label>
                  <input type="text" class="input-campos" v-model="descricao_fixa" required>
                </div>
              </div>

              <div class="flex w-full">
                <div class="w-full">
                  <label class="font-bold text-corBase">Descrição Para Orçamentos e Relatórios</label>
                  <input type="text" class="input-campos" v-model="descricao_variavel">
                </div>
              </div>
            </div>
          </div>

          <!-- Seção 2 -->
          <div>
            <button @click.prevent="toggleSection(2)"
                    class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Configurações
            </button>
            <div v-if="activeSection === 2" class="p-4 border border-corBase/60 flex flex-col gap-8">
              <!-- Campos de cadastro para a segunda seção -->
              <div class="flex gap-12 w-full">
                <div>
                  <label class="font-bold text-corBase">Unidade</label>
                  <input type="text" class="input-campos" v-model="unidade" required>
                </div>
                <div>
                  <label class="font-bold text-corBase">NCM</label>
                  <input type="text" class="input-campos" v-model="ncm" required>
                </div>

                <div class="w-80">
                  <label class="font-bold text-corBase">STATUS</label>
                  <Select2 v-model="status_projeto"
                           class="w-full z-10"
                           :options="lista_status"
                           :settings="{ width: '100%' }"
                  />
                </div>

              </div>

              <div class="flex gap-12 w-full">
                <!-- <div>
                  <labe class="text-corBase">KIT</labe>
                  <input type="text" class="input-campos" v-model="kit" required>
                </div> -->
                <div>
                  <label class="text-corBase">Grupo Cor de Vidros/Chapas</label>
                  <Select2 v-model="grupo_cor_vidros_chapas"
                           class="w-96 z-10"
                           :options="lista_grupo_cor_vidros_chapas"
                           :settings="{ width: '100%' }"
                  />
                </div>
                <div>
                  <label class="text-corBase">Grupo Cor de Perfis</label>
                  <Select2 v-model="grupo_cor_perfis"
                           class="w-96 z-10"
                           :options="lista_grupo_cor_perfis"
                           :settings="{ width: '100%' }"
                  />
                </div>
                <div>
                  <label class="text-corBase">Grupo Cor de Componentes</label>
                  <Select2 v-model="grupo_cor_componentes"
                           class="w-96 z-10"
                           :options="lista_grupo_cor_componentes"
                           :settings="{ width: '100%' }"
                  />
                </div>
              </div>

              <div class="flex gap-12 w-full">
                <div>
                  <label class="text-corBase">CFOP SAIDA</label>
                  <input type="text" class="input-campos" v-model="cfop_saida" required>
                </div>
                <div>
                  <label class="text-corBase">CFOP FORA UF</label>
                  <input type="text" class="input-campos" v-model="cfop_fora_uf" required>
                </div>

              </div>

            </div>
          </div>

          <!-- Seção 3 -->
          <div>
            <button @click.prevent="toggleSection(3)"
                    class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Esquadria
            </button>
            <div v-if="activeSection === 3" class="p-4 border border-corBase/60 flex flex-col gap-8">
              <!-- Campos de cadastro para a terceira seção -->
              <div class="flex items-end gap-12 w-full">
                <div>
                  <label class="text-corBase">TEMPO PRODUÇÃO</label>
                  <input type="text" class="input-campos" v-model="temp_producao">
                </div>
                <div>
                  <label class="text-corBase">TEMPO INSTALAÇÃO</label>
                  <input type="text" class="input-campos" v-model="temp_instalacao">
                </div>
              </div>

              <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Perfis</label>
                <Search
                  v-model="perfilSelecionado"
                  :options="lista_perfis"
                  labelKey="codigo"
                  valueKey="id"
                  nameKey="nome"
                  @select="onSelectPerfil"
                  @shouldSearch="getListaPerfis"
                  class="w-[85vw]"
                />

                <div v-if="perfis.length > 0">
                  <div 
                    v-for="(perfil, index) in perfis" 
                    :key="perfil.id" 
                    class="inline-flex gap-2 bg-slate-300 p-1 m-2"
                  >
                    <button @click.prevent="removerDoArray(perfis, index)">
                      <svg
                        class="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>

                    <p><span class="font-bold">{{ perfil.codigo }}</span> - {{ perfil.nome }}</p>

                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Vidros</label>
                <Search
                  v-model="vidroSelecionado"
                  :options="lista_vidros"
                  labelKey="codigo"
                  valueKey="id"
                  nameKey="nome"
                  @select="onSelectVidro"
                  @shouldSearch="getListaVidros"
                  class="w-[85vw]"
                />

                <div v-if="vidros.length > 0">
                  <div 
                    v-for="(vidro, index) in vidros" 
                    :key="vidro.id" 
                    class="inline-flex gap-2 bg-slate-300 p-1 m-2"
                  >
                    <button @click.prevent="removerDoArray(vidros, index)">
                      <svg
                        class="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>

                    <p><span class="font-bold">{{ vidro.codigo }}</span> - {{ vidro.nome }}</p>

                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Componentes</label>
                <Search
                  v-model="componenteSelecionado"
                  :options="lista_componentes"
                  labelKey="codigo"
                  valueKey="id"
                  nameKey="nome"
                  @select="onSelectComponente"
                  @shouldSearch="getListaComponentes"
                  class="w-[85vw]"
                />

                <div v-if="componentes.length > 0">
                  <div 
                    v-for="(componente, index) in componentes" 
                    :key="componente.id" 
                    class="inline-flex gap-2 bg-slate-300 p-1 m-2"
                  >
                    <button @click.prevent="removerDoArray(componentes, index)">
                      <svg
                        class="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>

                    <p><span class="font-bold">{{ componente.codigo }}</span> - {{ componente.nome }}</p>

                  </div>
                </div>
              </div>

              <div class="flex flex-col w-full">
                <label class="font-bold text-corBase">Serviços</label>
                <Search
                  v-model="servicoSelecionado"
                  :options="lista_servicos"
                  labelKey="codigo"
                  valueKey="id"
                  nameKey="nome"
                  @select="onSelectServico"
                  @shouldSearch="getListaServicos"
                  class="w-[85vw]"
                />
              </div>

              <div v-if="servicos.length > 0">
                  <div 
                    v-for="(servico, index) in servicos" 
                    :key="servico.id" 
                    class="inline-flex gap-2 bg-slate-300 p-1 m-2"
                  >
                    <button @click.prevent="removerDoArray(servicos, index)">
                      <svg
                        class="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-width="1.5"
                            d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>

                    <p><span class="font-bold">{{ servico.codigo }}</span> - {{ servico.nome }}</p>

                  </div>
                </div>

            </div>
          </div>

          <!-- Seção 4 -->
          <div>
            <button @click.prevent="toggleSection(4)"
                    class="w-full bg-gray-200 p-4 text-left border border-corBase/60">
              Normatização
            </button>
            <div v-if="activeSection === 4" class="p-4 border border-corBase/60 flex flex-col gap-8">
              <!-- Campos de cadastro para a quarta seção -->
              <div class="flex gap-12 w-full">
                <div>
                  <label class="font-bold text-corBase">ALTURA MINIMA</label>
                  <input type="text" class="input-campos" v-model="altura_minima" required>
                </div>
                <div>
                  <label class="font-bold text-corBase">ALTURA MAXIMA</label>
                  <input type="text" class="input-campos" v-model="altura_maxima" required>
                </div>
                <div>
                  <label class="font-bold text-corBase">LARGURA MINIMA</label>
                  <input type="text" class="input-campos" v-model="largura_minima" required>
                </div>
                <div>
                  <label class="font-bold text-corBase">LARGURA MAXIMA</label>
                  <input type="text" class="input-campos" v-model="largura_maxima" required>
                </div>
              </div>

              <div class="flex gap-12 w-full">
                <div class="w-full">
                  <label class="text-corBase">LISTA DE ESPESSURAS PERMITIDAS EX.: 4,6,8,10</label>
                  <Select2 v-model="espessuras"
                           class="w-full z-10"
                           :options="lista_espessuras"
                           :settings="{ width: '100%', multiple: true }"
                  />
                </div>
              </div>

            </div>
          </div>

          <!-- Botao para salvar -->
          <div class="flex w-full gap-8 justify-end">
            <router-link to="/lista-projetos" class="btn-enviar !bg-inativo">Cancelar</router-link>
            <button class="btn-enviar" type="submit">Salvar</button>
          </div>
        </div>
      </div>
    </form>

  </div>


  <continuar-criar-projeto v-if="continuar == true" :projeto="projeto_id"/>

</template>


<script>
import ContinuarCriarProjeto from "@/components/toasts/toast_sucesso/ContinuarCriarProjeto";
import axios from "axios";
import Select2 from "vue3-select2-component";
import Search from "@/utils/Search.vue";
import vSelect from "vue-select";

export default {
  name: 'CadastrosProjetos',

  components: {
    ContinuarCriarProjeto,
    Select2,
    Search,
    vSelect
  },

  data() {
    return {
      projeto_id: '',
      // variaveis dos formularios
      sistemista: '',
      tipologia: '',
      linha: '',
      cod_produto: '',
      descricao_fixa: '',
      descricao_variavel: '',
      unidade: '',
      ncm: '',
      lib_venda: true,
      grupo_cor_vidros_chapas: '',
      grupo_cor_perfis: '',
      grupo_cor_componentes: '',
      cfop_saida: '',
      cfop_fora_uf: '',
      temp_producao: '',
      temp_instalacao: '',
      status_projeto: '',
      perfis: [],
      vidros: [],
      servicos: [],
      componentes: [],
      altura_minima: '',
      altura_maxima: '',
      largura_minima: '',
      largura_maxima: '',
      espessuras: [],
      perfilSelecionado: {},
      vidroSelecionado: {},
      componenteSelecionado: {},
      servicoSelecionado: {},

      // viaveis para lista
      lista_sistemistas: [],
      lista_tipologias: [],
      lista_status: [],
      lista_perfis: [],
      lista_vidros: [],
      lista_servicos: [],
      lista_componentes: [],
      lista_espessuras: [],
      lista_linhas: [],
      lista_grupo_cor_vidros_chapas: [],
      lista_grupo_cor_componentes: [],
      lista_grupo_cor_perfis: [],

      // variaveis para manipulaçao de elementos
      activeSection: 1,
      continuar: false
    }
  },

  created() {
    this.getListaSistemistas()
    this.getListaTipologia()
    this.getListaStatus()
    this.getListaEspessuras()
    this.getListaCorPerfis()
    this.getListaCorComponentes()
    this.getListaCorVidros()
  },

  computed: {
    minhaEmpresa() {
      return this.$store.state.minhaEmpresa;
    },
  },

  watch: {
    sistemista(newValue){
      if(newValue){
        this.getListaLinhas(newValue)
      }

      if(!newValue) {
        this.linha = ''
        this.lista_linhas = []
      }
    }
  },

  methods: {

    removerDoArray(array, index) {
      array.splice(index, 1)
    },

    onSelectPerfil(perfil){
      if(perfil){
        this.perfis.push(perfil)
        this.perfilSelecionado = {}
      }
    },

    onSelectVidro(vidro){
      if(vidro){
        this.vidros.push(vidro)
        this.vidroSelecionado = {}
      }
    },

    onSelectComponente(componente){
      if(componente){
        this.componentes.push(componente)
        this.componenteSelecionado = {}
      }
    },

    onSelectServico(servico){
      if(servico){
        this.servicos.push(servico)
        this.servicoSelecionado = {}
      }
    },

    continuar_cadastro() {
      this.continuar = !this.continuar
    },

    toggleSection(sectionNumber) {
      // Alterna a exibição da seção com base no número do acordeão clicado
      this.activeSection = this.activeSection === sectionNumber ? null : sectionNumber;
    },

    async cadastrarProjeto() {

      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      const data = {
        codigo: this.cod_produto,
        descricao_fixa: this.descricao_fixa,
        descricao_variavel: this.descricao_variavel,
        ncm: this.ncm,
        producao: this.producao,
        instalacao: this.instalacao,
        tipologia: this.tipologia,
        status_projeto: this.status_projeto,
        linha_sistema: this.linha,
        unidade: this.unidade,
        cfop_saida: this.cfop_saida,
        cfop_fora_uf: this.cfop_fora_uf,
        altura_minima: this.altura_minima,
        altura_maxima: this.altura_maxima,
        largura_minima: this.largura_minima,
        largura_maxima: this.largura_maxima,
        espessuras_permitidas: this.espessuras,
        perfil: this.perfis.map((perfil) => perfil.id),
        vidro: this.vidros.map((vidro) => vidro.id),
        servico: this.servicos.map((servico) => servico.id),
        componente: this.componentes.map((componente) => componente.id)

      }
      this.$store.dispatch("setLoading", true);


      await axios.post('projeto/projeto/', data, {
        headers: {
          'Authorization': `Token ${token}`,
        }
      })
          .then(response => {
            console.log(response.data.results)
            this.projeto_id = response.data.results.id
            this.continuar_cadastro()
            this.$store.dispatch("setLoading", false);
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch("setLoading", false);
          })


    },

    async getListaSistemistas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/projetista-sistemista/?page_size=999', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_sistemistas = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }))
          })
          .catch(error => {
            console.log(error)
          })

    },

    async getListaCorPerfis(){
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-perfil/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_grupo_cor_perfis = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getListaCorComponentes() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-componente/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          this.lista_grupo_cor_componentes = response.data.results.map(item => ({
            id: item.id,
            text: item.nome
          }));
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getListaCorChapas() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-chapa/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          const result = response.data.results.map(item => ({
            id: item.id,
            text: `${item.nome} - CHAPA`
          }));

          this.lista_grupo_cor_vidros_chapas = [...result, ...this.lista_grupo_cor_vidros_chapas]
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getListaCorVidros() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/grupo-cor-vidro/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
        .then(response => {
          const result = response.data.results.map(item => ({
            id: item.id,
            text: `${item.nome} - VIDRO`
          }));

          this.lista_grupo_cor_vidros_chapas = [...result, ...this.lista_grupo_cor_vidros_chapas]
          this.getListaCorChapas()
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getListaTipologia() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/tipologia/', {
        headers: {
          Authorization: `Token ${token}`
        }
      })
          .then(response => {
            this.lista_tipologias = response.data.results.map(item => ({
              id: item.id,
              text: item.nome
            }));
          })
          .catch(error => {
            console.log(error)
          })

    },

    async getListaLinhas(sistemistaId){
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`projeto/linha-sistema/?projetista_sistemista_id=${sistemistaId}`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
      .then(response => {
            this.lista_linhas = response.data.results.map(item => ({
              id: item.id,
              text: item.linha
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },


    async getListaStatus() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('projeto/status-projeto', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_status = response.data.results.map(item => ({
              id: item.id,
              text: item.descricao
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },


    async getListaPerfis(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/?tipo_produto=Perfil&filtro_base=${pesquisa}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_perfis = response.data.results.map(item => ({
              id: item.id,
              codigo: item.codigo_base,
              nome: item.nome_base
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaVidros(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/?tipo_produto=Vidro&filtro_base=${pesquisa}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_vidros = response.data.results.map(item => ({
              id: item.id,
              codigo: item.codigo_base,
              nome: item.nome_base
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaComponentes(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/produto/?tipo_produto=Componente&filtro_base=${pesquisa}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_componentes = response.data.results.map(item => ({
              id: item.id,
              codigo: item.codigo_base,
              nome: item.nome_base
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaServicos(pesquisa) {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get(`produtos/servico/?filtro_base=${pesquisa}&page_size=999`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_servicos = response.data.results.map(item => ({
              id: item.id,
              codigo: item.codigo_fornecedor,
              nome: item.nome_especifico
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },

    async getListaEspessuras() {
      const token = await this.$store.dispatch('getAuthTokenCookie')
      await this.$store.dispatch('fetchURLrequest')

      await axios.get('produtos/espessura', {
        headers: {
          'Authorization': `Token ${token}`
        }
      })
          .then(response => {
            this.lista_espessuras = response.data.results.map(item => ({
              id: item.id,
              text: item.espessura
            }));
          })
          .catch(error => {
            console.log(error)
          })
    },
  }

}

</script>


<style lang="scss">
.input-disabled {
  position: relative;
  transition: opacity 0.3s ease, cursor 0.3s ease;

  &:disabled {
    opacity: 0.6;  // Deixa o botão opaco
    cursor: not-allowed;  // Muda o cursor para bloqueado

    // Estilização para a mensagem flutuante
    &::after {
      content: 'Selecione um sistemista'; // Mensagem flutuante
      position: absolute;
      top: -35px;  // Posição acima do botão
      left: -10%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.75);  // Fundo da mensagem
      color: white;  // Cor da mensagem
      padding: 5px 10px;  // Espaçamento interno
      border-radius: 4px;  // Bordas arredondadas
      font-size: 12px;  // Tamanho da fonte
      white-space: nowrap;
      opacity: 0;  // Inicialmente invisível
      transition: opacity 0.3s ease;
      pointer-events: none;  // Previne que a mensagem receba cliques
    }

    &:hover::after {
      opacity: 1;  // Torna a mensagem visível ao passar o mouse
    }
  }
}

/* Estilo para a caixa de pesquisa */
.select2-container .select2-selection--single {
  box-sizing: border-box;
  appearance: none;
  cursor: pointer;
  display: block;
  height: 43px;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container--default .select2-search__field {
  border: 0px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 10px;
  right: 3px;
  color: rgba(22, 78, 99, 1);
  width: 20px;
}

/* Estilo para a lista de opções */
.select2-container--default .select2-results__option {
  padding: 5px;
  cursor: pointer;
}

/* Estilo para a opção selecionada */
.select2-container--default .select2-selection--single {
  border-top: 2px solid rgba(22, 78, 99, 0.1);
  border-left: 2px solid rgba(22, 78, 99, 0.1);
  border-right: 2px solid rgba(22, 78, 99, 0.1);
  border-bottom: 2px solid rgba(22, 78, 99, 0.2);
  border-radius: 0px;
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

/* Estilo quando o elemento está em foco */
.select2-container--default .select2-selection--single:focus {
  border-bottom: 2px solid rgba(22, 78, 99, 0.8);
}

/* Estilo select multiple */
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border-top: 2px solid rgba(22, 78, 99, 0.1);
  border-left: 2px solid rgba(22, 78, 99, 0.1);
  border-right: 2px solid rgba(22, 78, 99, 0.1);
  border-bottom: 2px solid rgba(22, 78, 99, 0.2);
  border-radius: 0px !important;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  appearance: none;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
</style>
