<template>
    <div>
        <div class="form-group">
            <label for="descricao">Descrição da Obra</label>
            <input v-model="obra.descricao" type="text" id="descricao" placeholder="Digite aqui..." />
        </div>

        <div class="form-group">
            <label for="status">Status da Obra</label>
            <v-select v-model="obra.status_obra" :options="statusOptions" label="label" placeholder="Selecione o status"
                :reduce="status_obra => status_obra.value" />
        </div>

        <div class="form-group">
            <label for="imagem" class="text-corBase pt-1">Imagem</label>
            <button @click.prevent="abrir_galeria">
                <i class="fa-solid fa-image fa-xl"></i>
                Escolha uma Imagem
            </button>
        </div>

        <div class="flex w-full justify-end itens-center mt-6">
            <button type="submit" @click="$emit('proximaEtapa')" class="btn-salvar">Avançar</button>
        </div>
    </div>

    <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>


<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Galeria from '@/components/midais/ged/Galeria.vue';

export default {
    name: 'Obra',
    components: {
        vSelect,
        Galeria
    },
    props: {
        obra: Object
    },
    data() {
        return {
            statusOptions: [
                { value: 'PE', label: 'Pendente' },
                { value: 'CA', label: 'Cancelado' },
                { value: 'PL', label: 'Planejamento' },
                { value: 'CR', label: 'Construção Inicial' },
                { value: 'AC', label: 'Acabamento' },
                { value: 'RF', label: 'Reforma' },
                { value: 'DM', label: 'Demolição' },
                { value: 'OR', label: 'Orçado' },
                { value: 'EX', label: 'Execução' },
                { value: 'CO', label: 'Concluído' },
            ],

            galeria: false,
        };
    },
    methods: {
        // Funções relacionadas a imagem do bucket
        abrir_galeria() {
            this.galeria = !this.galeria
        },

        atualizarFileInput(objeto) {
            this.obra.imagem = objeto.id;
        },
    }
};
</script>

<style scoped>
.etapas-navegacao {
    display: flex;
    justify-content: center;
    /* Espaço entre os botões */
    overflow-x: auto;
    /* Permite rolagem horizontal se necessário */
}

.etapas-navegacao button {
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin: 20px;
}

.etapas-navegacao .ativo {
    background-color: #1f2937;
    color: white;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>