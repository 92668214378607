<template>
    <div>
        <!-- Campo de imagem ocupando a largura total -->
        <div class="form-group">
            <label for="imagem" class="text-corBase pt-1">Imagem</label>
            <button @click.prevent="abrir_galeria" v-if="!vao.imagem">
                <i class="fa-solid fa-image fa-xl"></i>
                <p>Escolha uma Imagem</p>
            </button>

            <!-- Exibe a pré-visualização da imagem quando uma imagem é selecionada -->
            <div v-else>
                <button @click.prevent="abrir_galeria">
                    <i class="fa-solid fa-image fa-xl"></i>
                    <p>Trocar Imagem</p>
                </button>
            </div>

        </div>

        <!-- Largura, Altura e Local do Vão na mesma linha -->
        <div class="form-group flex flex-col md:flex-row gap-4">
            <div class="w-full">
                <label for="largura" class="text-corBase pt-2">Largura (MM)</label>
                <input v-model.number="vao.largura" type="number" min="0" id="largura" placeholder="Digite aqui..."
                    class="w-full" required />
            </div>
            <div class="w-full">
                <label for="altura" class="text-corBase pt-2">Altura (MM)</label>
                <input v-model.number="vao.altura" type="number" min="0" id="altura" placeholder="Digite aqui..."
                    class="w-full" required />
            </div>
            <div class="w-full">
                <label for="local_vao" class="text-corBase pt-1">Local do Vão</label>
                <input v-model="vao.local_vao" type="text" id="local_vao" placeholder="Digite aqui..." class="w-full"
                    required />
            </div>
        </div>

        <!-- Cores lado a lado na mesma linha -->
        <div class="form-group">
            <h2 class="text-lg text-corBase font-bold pb-0 pt-2">Cores</h2>
            <div class="flex flex-col gap-6 md:flex-row pb-6">
                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Componentes</label>
                    <Select2 v-model="vao.corComponentes" class="w-full text-xs z-10" :options="listaCorComponentes"
                        :settings="{ width: '100%', placeholder: 'Escolha uma opção' }" />
                </div>

                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Perfis</label>
                    <Select2 v-model="vao.corPerfis" class="w-full text-xs z-10" :options="listaCorPerfis"
                        :settings="{ width: '100%', placeholder: 'Escolha uma opção' }" />
                </div>

                <div class="flex flex-col w-full">
                    <label class="font-bold text-corBase">Vidros</label>
                    <Select2 v-model="vao.corVidros" class="w-full text-xs z-10" :options="listaCorVidros"
                        :settings="{ width: '100%', placeholder: 'Escolha uma opção' }" />
                </div>
            </div>

            <!-- Ajuste para centralizar o checkbox abaixo do texto e torná-lo responsivo -->
            <div class="flex w-full flex-wrap gap-4">
                <!-- Tipo Medida Contramarco -->
                <div class="flex flex-col gap-2 w-full md:w-[32%]">
                    <label class="font-bold text-corBase">Tipo Medida Contramarco</label>
                    <Select2 v-model="orcamento.tipoMedidaContramarco" class="w-full" :options="listaTiposMedidas"
                        :settings="{ width: '100%', placeholder: 'Escolha uma opção' }" />
                </div>

                <!-- Contramarco -->
                <div class="flex flex-col items-center gap-2 w-full md:w-[10%]">
                    <label class="font-bold text-corBase">Contramarco</label>
                    <input type="checkbox" class="text-corBase border border-corBase/40 w-5 h-5"
                        v-model="orcamento.contramarco">
                </div>

                <!-- Arremate -->
                <div class="flex flex-col items-center gap-2 w-full md:w-[10%]">
                    <label class="font-bold text-corBase">Arremate</label>
                    <input type="checkbox" class="text-corBase border border-corBase/40 w-5 h-5"
                        v-model="orcamento.arremate">
                </div>

                <!-- Arremate Piso -->
                <div class="flex flex-col items-center gap-2 w-full md:w-[10%]">
                    <label class="font-bold text-corBase">Arremate Piso</label>
                    <input type="checkbox" class="text-corBase border border-corBase/40 w-5 h-5"
                        v-model="orcamento.arrematePiso">
                </div>
            </div>
        </div>
    </div>

    <Galeria v-if="galeria === true" @fechar_galeria="abrir_galeria" @objetoClicado="atualizarFileInput" />
</template>


<script>
import Select2 from 'vue3-select2-component';
import axios from 'axios'
import Galeria from '@/components/midais/ged/Galeria.vue';

export default {
    data() {
        return {
            listaTiposMedidas: ['Externa', 'Interna'],
            listaCorComponentes: [],
            listaCorPerfis: [],
            listaCorVidros: [],

            galeria: false,

            previewImage: {},
        }
    },
    props: ['vao', 'orcamento',],
    components: {
        Select2,
        Galeria
    },
    created() {
        this.getlistaCorComponentes()
        this.getlistaCorPerfis()
        this.getlistaCorVidros()
    },
    methods: {

        // Funções relacionadas a imagem do bucket
        abrir_galeria() {
            this.galeria = !this.galeria
        },

        atualizarFileInput(objeto) {
            this.vao.imagem = objeto;
        },

        // Funções relacionadas à sessão de cor
        async getlistaCorComponentes() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-componente/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorComponentes = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getlistaCorPerfis() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-perfil/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorPerfis = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },

        async getlistaCorVidros() {
            try {
                const token = await this.$store.dispatch('getAuthTokenCookie')
                await this.$store.dispatch('fetchURLrequest')

                const response = await axios.get('produtos/cor-vidro/?page_size=999', {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                })

                this.listaCorVidros = response.data.results.map(response => {
                    return {
                        id: response.id,
                        text: response.nome
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },
    },
};
</script>

<style scoped>
label {
    display: block;
    font-weight: bold;
}

input[type="file"],
input[type="number"],
input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.abas-container {
    display: flex;
    margin-top: 15px;
    @apply mt-4 p-2 border-b rounded-lg
}

.aba {
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 700;
    transition: 0.3s ease-in-out;
    @apply rounded-lg bg-white text-corBase border border-white
}

.aba-selecionada {
    @apply bg-corBase text-white border border-white
}
</style>