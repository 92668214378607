import { createStore } from "vuex";
import axios from "axios";
import Cookies from "js-cookie";

export default createStore({
  state: {
    // inicializa o valor dos estados da store
    isLoading: false,
    isAuthenticated: false,
    token: "",
    isAdminUser: false,

    // variavel de estado que armazena o schema com o nome da empresa
    minhaEmpresa: "",

    // urls base
    baseProtocol: "http://",
    baseApiUrl: `managerglass.com.br:8000/api/v1`,
    baseAppUrl: "managerglass.com.br",

    // variaveis para controlar a inclusão de projetos em orçamentos
    orcamentoIdAtual: "",
    projetoIdAtual: "",
    descricaoItemOrcamento: "",
  },

  getters: {},

  mutations: {
    // inicializa a store quando criado o app
    initializeStore(state) {
      // Verifica se o token está presente no localStorage e inicializa o estado
      if (localStorage.getItem("token")) {
        state.token = localStorage.getItem("token");
        state.isAuthenticated = true;
      } else {
        state.token = "";
        state.isAuthenticated = false;
      }

      // Verifica se isAdminUser está presente no localStorage e inicializa o estado
      if (localStorage.getItem("isAdminUser")) {
        state.isAdminUser = localStorage.getItem("isAdminUser") === "true";
      } else {
        state.isAdminUser = false; // Defina o valor padrão como false
      }
    },

    // manipula o estado do componende de loading a ser criado
    setLoading(state, status) {
      state.isLoading = status;
    }, // adiciona valor do token a store e autentica
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    }, // remove valor do token e tira a autenticação
    removeToken(state) {
      state.token = "";
      state.isAuthenticated = false;
    },

    setMinhaEmpresa(state, minhaEmpresa) {
      state.minhaEmpresa = minhaEmpresa;
    },

    removeMinhaEmpresa(state) {
      state.minhaEmpresa = "";
    },

    setIsAdminUser(state, value) {
      state.isAdminUser = value;
      localStorage.setItem("isAdminUser", value.toString()); // Converte para string antes de salvar
    },
  },

  actions: {
    setAuthTokenCookie({ commit }, authToken) {
      // Defina o cookie com um nome e valor específicos
      Cookies.set("authToken", authToken, { expires: 1 }); // Expira em 7 dias
    },

    // Ação para ler o cookie de autenticação
    getAuthTokenCookie() {
      // Leia o cookie
      const authToken = Cookies.get("authToken");
      return authToken;
    },

    // Ação para remover o cookie de autenticação
    removeAuthTokenCookie() {
      // Remova o cookie
      Cookies.remove("authToken");
    },

    updateIsAdminUser({ commit }, value) {
      commit("setIsAdminUser", value);
    },

    async fetchURLrequest({ state }) {
        const hostname = window.location.hostname;
        const port = window.location.port;  // Captura a porta atual da URL
        const subdomain = hostname.split('.')[0];
        let apiURL;
    
        // Verificar se está no ambiente de desenvolvimento (localhost)
        if (hostname === 'localhost') {
          // Se for localhost, usa a API na porta 8000
          apiURL = `http://localhost:8000/api/v1`;
        } else if (hostname === 'admin.localhost') {
          // Se for admin.localhost, usa admin.localhost com a porta 8000
          apiURL = `http://localhost:8000/api/v1`;
        } else if (subdomain === 'www' || subdomain === '' || subdomain === 'admin') {
          // Caso o subdomínio seja "www" ou não exista subdomínio
          apiURL = `http://${state.baseApiUrl}`;
        }else {
          // Caso exista um subdomínio e adicionar a porta
          apiURL = `http://${subdomain}.${state.baseApiUrl}`;
        }
    
        // Definir a baseURL no Axios
        axios.defaults.baseURL = apiURL;
    
        return apiURL;
      },

    // requisiçao para retornar a lista de pessoaJuridica filtrada ou nao

    async fetchListaProdutoBase({ commit }) {
      try {
        const token = this.state.token;
        if (this.isadmin === true) {
          let apiURL = `http://${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        } else {
          let apiURL = `http://${this.state.minhaEmpresa}.${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        }

        const response = await axios.get("produtos/produto", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        return response.data.results; // Retornar os dados da chamada
      } catch (error) {
        // Trate os erros conforme necessário
        throw error; // Lança o erro para ser tratado no componente
      }
    },

    async fetchListaFornecedor({ commit }) {
      try {
        const token = this.state.token;
        if (this.isadmin === true) {
          let apiURL = `http://${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        } else {
          let apiURL = `http://${this.state.minhaEmpresa}.${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        }

        const response = await axios.get("pessoas/fornecedor", {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        return response.data.results; // Retornar os dados da chamada
      } catch (error) {
        // Trate os erros conforme necessário
        throw error; // Lança o erro para ser tratado no componente
      }
    },

    // requisição para buscar documentos dentro da aws
    async fetchListaDocumentos(
      { commit },
      { descricao, subcategoria, categoria }
    ) {
      try {
        const token = this.state.token;
        if (this.state.isAdminUser === true) {
          let apiURL = `http://${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        } else {
          let apiURL = `http://${this.state.minhaEmpresa}.${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        }

        const response = await axios.get(
          `ged/documentos/?descricao=${descricao}&categoria_id=${categoria}&subcategoria_id=${subcategoria}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        return response.data.results; // Retornar os dados da chamada
      } catch (error) {
        // Trate os erros conforme necessário
        throw error; // Lança o erro para ser tratado no componente
      }
    },

    async fetchListaCategoriasGed({ commit }) {
      try {
        const token = this.state.token;
        if (this.state.isAdminUser === true) {
          let apiURL = `http://${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        } else {
          let apiURL = `http://${this.state.minhaEmpresa}.${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        }

        const response = await axios.get(`ged/categoria`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        return response.data.results; // Retornar os dados da chamada
      } catch (error) {
        // Trate os erros conforme necessário
        throw error; // Lança o erro para ser tratado no componente
      }
    },

    async fetchListaSubcategoriasGed({ commit }) {
      try {
        const token = this.state.token;
        if (this.state.isAdminUser === true) {
          let apiURL = `http://${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        } else {
          let apiURL = `http://${this.state.minhaEmpresa}.${this.state.baseApiUrl}`;
          axios.defaults.baseURL = apiURL;
        }

        const response = await axios.get(`ged/subcategoria`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        return response.data.results; // Retornar os dados da chamada
      } catch (error) {
        // Trate os erros conforme necessário
        throw error; // Lança o erro para ser tratado no componente
      }
    },

    setLoading({ commit }, status) {
      commit("setLoading", status);
    },
  },
  modules: {},
});
